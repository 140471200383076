import React from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import Button from '~components/button';
import { getValidatedQueryString } from '~helpers/queryUtils';

const AddOffer = () => {
  const intl = useIntl();
  const location = useLocation();

  if (location.pathname !== '/workers' && location.pathname !== '/customers') return null;
  const search = queryString.parse(getValidatedQueryString(location.search)).locationId;
  if (!search) {
    return null;
  }

  return (
    <Link
      to={
        location.pathname === '/workers' ? `/intake/client?locationId=${search}&showFields=true` : `/intake/worker?locationId=${search}&showFields=true`
      }
      rel="nofollow"
    >
      <Button className="c-btn--secondary c-header__offer-button">
        {intl.formatMessage({
          id: 'components.header.add-offer-button',
          defaultMessage: 'Add your own offer',
        })}
      </Button>
    </Link>
  );
};

export default AddOffer;
