import React from 'react';
import cx from 'classnames';

import Cities from './cities';
import Copyright from './copyright';
import Socials from './socials';

import './style.scss';

const Footer = ({ displayCities = false, displayNeighborhoods = false, isCityPage = false }) => {
  return (
    <footer className={cx('c-footer', { 'c-footer--city-page': isCityPage })}>
      {displayCities && <Socials />}
      {displayCities && <Cities displayNeighborhoods={displayNeighborhoods} />}
      <Copyright />
    </footer>
  );
};
 
export default Footer;
