import React, { memo } from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';

import './style.scss';

const DesktopMenu = memo(() => {
  const intl = useIntl();
  const { pathname } = useLocation();
  return (
    <div className="c-desktop-menu c-horizontal-nav">
      <FormattedMessage id="routes.faq">
        {(msg) => (
          <Link
            className={cx('c-horizontal-nav__item', {
              'c-horizontal-nav__item--active': matchPath(`/${msg}`, { path: pathname, exact: true }),
            })}
            to={`/${msg}`}
          >
            {intl.formatMessage({
              id: 'components.header.menu.faq',
              defaultMessage: 'FAQ',
            })}
          </Link>
        )}
      </FormattedMessage>

      <FormattedMessage id="routes.contact">
        {(msg) => (
          <Link
            className={cx('c-horizontal-nav__item', {
              'c-horizontal-nav__item--active': matchPath(`/${msg}`, { path: pathname, exact: true }),
            })}
            to={`/${msg}`}
          >
            {intl.formatMessage({
              id: 'components.header.menu.contact',
              defaultMessage: 'Contact',
            })}
          </Link>
        )}
      </FormattedMessage>

      {/* <Link
        to="/intake/worker"
        rel="nofollow"
        className={cx('c-horizontal-nav__item', {
          'c-horizontal-nav__item--active': matchPath(`/intake/worker`, { path: pathname, exact: true }),
        })}
      >
        {intl.formatMessage({
          id: 'components.header.menu.worker-intake',
          defaultMessage: 'Work as help',
        })}
      </Link> */}

      <Link
        to="/intake/worker"
        rel="nofollow"
        className={cx('c-horizontal-nav__item c-horizontal-nav__item--primary', {
          'c-horizontal-nav__item--active': matchPath(`/intake/worker`, { path: pathname, exact: true }),
        })}
      >
        {intl.formatMessage({
          id: 'components.header.menu.worker-intake',
          defaultMessage: 'Work as help',
        })}
      </Link>
    </div>
  );
});

export default DesktopMenu;
