import { createIntl, createIntlCache } from 'react-intl';

const intlProvider = {};
let intl;
let previousLocale;
let wasCrowdin;

intlProvider.getInstance = () => {
  return intl;
};

intlProvider.setInstance = (i, locale, isCrowdin) => {
  intl = i;
  previousLocale = locale;
  wasCrowdin = isCrowdin;
};

intlProvider.initialize = ({ locale, messages, isCrowdin }) => {
  if (locale && (locale !== previousLocale || wasCrowdin !== isCrowdin)) {
    const cache = createIntlCache();

    const i = createIntl(
      {
        locale,
        messages,
        onError: (err) => {
          // Only log missing translation warnings in development
          if (process.env.NODE_ENV !== 'production') {
            console.warn(err);
          }
        }
      },
      cache
    );

    intlProvider.setInstance(i, locale, isCrowdin);
    return intlProvider;
  }
};

intlProvider.clear = () => {
  intl = null;
  previousLocale = null;
  wasCrowdin = null;
};

export default intlProvider;
