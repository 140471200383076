import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { useGet } from '~hooks/useQuery';
import { isVerified, stringToJSON } from '~helpers/common';

import Carousel from '~components/carousel';
import Button from '~components/button';
import CarouselSkeleton from '~components/carousel/skeleton';

import ServerContext from '~src/serverContext';

import { Suspense, ErrorBoundary } from '~components/suspense';
import { useIntl } from 'react-intl';

import WorkerCard from '~components/card/worker';

const List = () => {
  const intl = useIntl();

  const cache = useContext(ServerContext);

  const { data, isLoading } = useGet(
    {
      name: `workers`,
      params: {
        domain: true,
      },
      cacheData: cache.get('serverHomepageWorkers'),
      mockFileName: 'workers/get-workers-by-domain',
    },
    []
  );

  return (
    <>
      <div className="c-wc">
        <div className="c-wc__subtitle">
          {intl.formatMessage({
            id: 'containers.homepage.list.subtitle',
            defaultMessage: 'Looking for a cleaner?',
          })}
        </div>
        <div className="c-wc__title">
          {intl.formatMessage({
            id: 'containers.homepage.list.title',
            defaultMessage: 'Here are some!',
          })}
        </div>
      </div>
      <Suspense fallback={<CarouselSkeleton skeletonCount={4} />} isLoading={isLoading}>
        <ErrorBoundary fallback={<h2 className="l-error">Could not load cleaners data</h2>}>
          <Carousel>
            {data &&
              data.data &&
              data.data
                .slice(0, 4)
                .map((worker, index) => (
                  <WorkerCard
                    key={worker.id}
                    personId={worker.id}
                    score={worker.score}
                    price={worker.price}
                    address={worker.location}
                    experience={worker.experience}
                    firstName={worker.first_name}
                    profilePicture={stringToJSON(worker.profile_picture)}
                    verification={isVerified(worker.verification)}
                    lastLogin={worker.last_login}
                    registerDate={worker.registered_date}
                    isOnline={worker.is_online}
                    personalNote={worker.personal_note}
                    published={worker.published}
                  />
                ))}
          </Carousel>
        </ErrorBoundary>
      </Suspense>
      <div className="c-wc__button-wrapper">
        <Link to="/intake/client" rel="nofollow">
          <Button className="c-btn--text large">
            {intl.formatMessage({
              id: 'containers.homepage.list.button',
              defaultMessage: 'Find your help!',
            })}
          </Button>
        </Link>
      </div>
    </>
  );
};

export default List;
